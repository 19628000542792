$hero-height: 25vh;

.hero-section {
  background: url('http://www.ags-svoboda.cz/assets/img/hero-section.jpg') 50% no-repeat;
  background-size: cover;
  height: $hero-height;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .hero-section-text {
    color: $white;
    text-shadow: 1px 1px 2px $black;
  }
}
